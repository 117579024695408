import React, { FC, useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import classes from './VideoPlayer.module.scss';
import { queueScreenPlayBtnIcon } from '../../../../assets/icons';
import classNames from 'classnames';
import {
  isQueueScreenVideoAutoplayEnabled,
  videoVolume,
  isQueueScreenVideoSoundEnabled
} from '../../../../constants/API';

type VideoPlayerProps = {
  videoPlaylist: string[];
  isPauseVideo: boolean;
};

const VideoPlayer: FC<VideoPlayerProps> = ({ videoPlaylist, isPauseVideo }) => {
  const [isPlaying, setIsPlaying] = useState(isQueueScreenVideoAutoplayEnabled);
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const [isUserInteracted, setIsUserInteracted] = useState(false);

  const handlePlayNextVideo = () => {
    setCurrentVideoIndex((prevIndex) =>
      prevIndex === videoPlaylist.length - 1 ? 0 : prevIndex + 1
    );

    if (isQueueScreenVideoAutoplayEnabled) {
      setIsPlaying(true);
    }
  };

  const handlePlayPause = () => {
    if (isPlaying) {
      setIsPlaying(false);
    } else {
      setIsPlaying(true);
    }
  };

  useEffect(() => {
    if (!isPauseVideo) {
      setIsPlaying(true);
    }
  }, [isPauseVideo]);

  useEffect(() => {
    if (!isQueueScreenVideoSoundEnabled) {
      return;
    }

    const handleUserInteraction = () => {
      setIsUserInteracted(true);
    };

    if (isUserInteracted) {
      window.removeEventListener('click', handleUserInteraction);
      return;
    } else {
      window.addEventListener('click', handleUserInteraction);
    }

    return () => {
      window.removeEventListener('click', handleUserInteraction);
    };
  }, [isUserInteracted]);

  return (
    <div className={classes.videoPlayerWrapper}>
      <ReactPlayer
        playing={isPlaying && !isPauseVideo}
        volume={isUserInteracted && isQueueScreenVideoSoundEnabled ? videoVolume : 0}
        url={videoPlaylist[currentVideoIndex]}
        onEnded={handlePlayNextVideo}
        controls={isPlaying ? true : false}
        onPlay={() => setIsPlaying(true)}
        onPause={() => setIsPlaying(false)}
        width="100%"
        height="100%"
      />

      <button
        onClick={handlePlayPause}
        className={classNames(classes.playBtn, {
          [classes.hideBtn]: isPlaying
        })}>
        <span className={classNames(classes.wave, classes.wave1)}></span>
        <span className={classNames(classes.wave, classes.wave2)}></span>
        <span className={classNames(classes.wave, classes.wave3)}></span>
        <img className={classes.imgIcon} src={queueScreenPlayBtnIcon} alt="PlayBtnIcon" />
      </button>
    </div>
  );
};

export default VideoPlayer;
