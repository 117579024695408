import React, { FC } from 'react';
import classes from './IconsStyles.module.scss';
import classNames from 'classnames';
import { isQueueScreenDarkTheme } from '../../constants/API';

export const HeaderClockIcon: FC = () => {
  return (
    <>
      <svg
        className={classNames(classes.headerClockIcon, {
          [classes.darkTheme]: isQueueScreenDarkTheme
        })}
        viewBox="0 0 38 38"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M19 0C29.4937 0 38 8.5063 38 19C38 29.4937 29.4937 38 19 38C8.5063 38 0 29.4937 0 19C0 8.5063 8.5063 0 19 0ZM19 3.8C14.9687 3.8 11.1025 5.40142 8.25198 8.25198C5.40142 11.1025 3.8 14.9687 3.8 19C3.8 23.0313 5.40142 26.8975 8.25198 29.748C11.1025 32.5986 14.9687 34.2 19 34.2C23.0313 34.2 26.8975 32.5986 29.748 29.748C32.5986 26.8975 34.2 23.0313 34.2 19C34.2 14.9687 32.5986 11.1025 29.748 8.25198C26.8975 5.40142 23.0313 3.8 19 3.8ZM19 7.6C19.4654 7.60006 19.9145 7.77092 20.2623 8.08016C20.6101 8.3894 20.8323 8.81552 20.8867 9.2777L20.9 9.5V18.2134L26.0433 23.3567C26.3841 23.6986 26.5819 24.1574 26.5966 24.6399C26.6114 25.1224 26.4419 25.5925 26.1226 25.9545C25.8034 26.3166 25.3583 26.5436 24.8777 26.5894C24.3971 26.6351 23.9172 26.4963 23.5353 26.201L23.3567 26.0433L17.6567 20.3433C17.3614 20.0477 17.1717 19.6631 17.1171 19.2489L17.1 19V9.5C17.1 8.99609 17.3002 8.51282 17.6565 8.1565C18.0128 7.80018 18.4961 7.6 19 7.6Z"
          fill={isQueueScreenDarkTheme ? 'white' : 'black'}
        />
      </svg>
    </>
  );
};
