import React, { FC } from 'react';
import classes from './IconsStyles.module.scss';
import { isQueueScreenDarkTheme } from '../../constants/API';

export const HeaderLogoIcon: FC = () => {
  return (
    <>
      <svg
        className={classes.headerLogoIcon}
        viewBox="0 0 290 68"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_2980_4728)">
          <path d="M187.646 0.5H0V67.0621H187.646V0.5Z" fill="url(#paint0_linear_2980_4728)" />
          <path
            d="M19.5055 26.0004H10.4132V17.6662H39.0651V26.0004H29.9729V49.5459H19.5055V26.0004ZM67.5975 41.4394V49.5459H41.7841V17.6662H67.0208V25.7728H52.074V29.5073H65.2025V37.2495H52.074V41.4394H67.5975ZM102.23 17.6662V49.5459H91.763V37.7959H82.0941V49.5459H71.6267V17.6662H82.0941V28.9607H91.763V17.6662H102.23ZM137.877 17.6662V49.5459H129.273L117.475 35.0634V49.5459H107.274V17.6662H115.878L127.676 32.1488V17.6662H137.877ZM159.198 50.2747C155.857 50.2747 152.856 49.5609 150.195 48.134C147.533 46.7073 145.449 44.7334 143.941 42.2136C142.433 39.6632 141.679 36.7941 141.679 33.606C141.679 30.4181 142.433 27.5641 143.941 25.0441C145.449 22.4937 147.533 20.5049 150.195 19.078C152.856 17.651 155.857 16.9375 159.198 16.9375C162.539 16.9375 165.541 17.651 168.202 19.078C170.863 20.5049 172.948 22.4937 174.456 25.0441C175.964 27.5641 176.718 30.4181 176.718 33.606C176.718 36.7941 175.964 39.6632 174.456 42.2136C172.948 44.7334 170.863 46.7073 168.202 48.134C165.541 49.5609 162.539 50.2747 159.198 50.2747ZM159.198 41.576C160.499 41.576 161.667 41.2572 162.702 40.6196C163.767 39.9515 164.609 39.0256 165.23 37.8415C165.851 36.627 166.162 35.2151 166.162 33.606C166.162 31.9969 165.851 30.6003 165.23 29.4161C164.609 28.2017 163.767 27.2756 162.702 26.638C161.667 25.97 160.499 25.6362 159.198 25.6362C157.897 25.6362 156.715 25.97 155.65 26.638C154.615 27.2756 153.787 28.2017 153.166 29.4161C152.545 30.6003 152.235 31.9969 152.235 33.606C152.235 35.2151 152.545 36.627 153.166 37.8415C153.787 39.0256 154.615 39.9515 155.65 40.6196C156.715 41.2572 157.897 41.576 159.198 41.576Z"
            fill="white"
          />
          <path
            d="M208.575 50.2747C206.003 50.2747 203.489 49.9862 201.035 49.4093C198.61 48.8022 196.615 47.9974 195.047 46.9956L198.418 39.1622C199.897 40.0427 201.552 40.7562 203.386 41.3028C205.219 41.8187 206.978 42.077 208.664 42.077C210.112 42.077 211.147 41.9402 211.769 41.667C212.389 41.3633 212.701 40.9233 212.701 40.3464C212.701 39.6782 212.285 39.1772 211.457 38.8434C210.66 38.5097 209.329 38.1453 207.466 37.7505C205.072 37.2344 203.074 36.6878 201.479 36.1109C199.883 35.5036 198.492 34.5321 197.309 33.1962C196.127 31.8299 195.534 29.993 195.534 27.6856C195.534 25.6816 196.082 23.8599 197.176 22.2205C198.271 20.5808 199.897 19.2905 202.056 18.3493C204.243 17.4081 206.89 16.9375 209.994 16.9375C212.122 16.9375 214.209 17.1804 216.248 17.6662C218.317 18.1216 220.137 18.8048 221.703 19.7156L218.555 27.5034C215.508 25.9245 212.626 25.1352 209.906 25.1352C207.214 25.1352 205.869 25.8031 205.869 27.139C205.869 27.7766 206.268 28.2624 207.067 28.5964C207.865 28.9 209.181 29.2341 211.015 29.5983C213.38 30.0537 215.376 30.585 217.002 31.1923C218.629 31.7693 220.032 32.7255 221.216 34.0614C222.428 35.3973 223.034 37.219 223.034 39.5266C223.034 41.5304 222.488 43.3521 221.393 44.9917C220.299 46.6007 218.658 47.8913 216.471 48.8628C214.312 49.8042 211.68 50.2747 208.575 50.2747ZM238.79 38.9801L236.439 41.667V49.5459H226.149V17.6662H236.439V29.2341L246.64 17.6662H258.038L245.619 31.6933L258.66 49.5459H246.551L238.79 38.9801ZM276.089 37.9781V49.5459H265.622V37.8415L253.911 17.6662H264.955L271.299 28.6875L277.686 17.6662H287.798L276.089 37.9781Z"
            fill={isQueueScreenDarkTheme ? 'white' : 'black'}
          />
        </g>
        <defs>
          <linearGradient
            id="paint0_linear_2980_4728"
            x1="1.17343e-06"
            y1="33.9169"
            x2="187.646"
            y2="33.9169"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#5198F6" />
            <stop offset="1" stopColor="#56C5F2" />
          </linearGradient>
          <clipPath id="clip0_2980_4728">
            <rect width="290" height="67" fill="white" transform="translate(0 0.5)" />
          </clipPath>
        </defs>
      </svg>
    </>
  );
};
