import React, { useEffect } from 'react';
import QueueHeader from '../../components/Main/QueueHeader/QueueHeader';
import QueuePanel from '../../components/Main/QueuePanel/QueuePanel';
import classes from './QueuePage.module.scss';
import { useNavigate } from 'react-router-dom';
import { useGetQueueTickers } from '../../hooks/MonitorQueue/useGetQueueTickers';
import { StaticTokens, isQueueScreenDarkTheme } from '../../constants/API';
import { FooterAsterixIcon } from '../../assets/svgComponents/FooterAsterixIcon';
import classNames from 'classnames';

const QueuePage = () => {
  const navigate = useNavigate();
  const { queueTickersResp } = useGetQueueTickers();

  useEffect(() => {
    navigate(`/queue/?token=${StaticTokens.monitorToken}`);
  }, []);

  return (
    <div
      className={classNames(classes.queuePageContainer, {
        [classes.darkQueuePageContainer]: isQueueScreenDarkTheme
      })}>
      <QueueHeader />
      <QueuePanel />
      <footer
        className={classNames(classes.footerContainer, {
          [classes.darkThemeFooterContainer]: isQueueScreenDarkTheme
        })}>
        {queueTickersResp && (
          <div
            className={classNames(classes.footerText, {
              [classes.darkThemeFooterText]: isQueueScreenDarkTheme
            })}>
            {queueTickersResp.tickers.map((e, idx) => (
              <div className={classes.runningString} key={idx}>
                <FooterAsterixIcon />
                <span>{e.text}</span>
              </div>
            ))}
          </div>
        )}
      </footer>
    </div>
  );
};

export default QueuePage;
