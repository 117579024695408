import React, { useEffect, useState } from 'react';
import styles from './QueueHeader.module.scss';
import { format } from 'date-fns';
import { ru } from 'date-fns/locale';
import { HeaderCalendarIcon } from '../../../assets/svgComponents/HeaderCalendarIcon';
import { HeaderClockIcon } from '../../../assets/svgComponents/HeaderClockIcon';
import { HeaderLogoIcon } from '../../../assets/svgComponents/HeaderLogoIcon';
import classNames from 'classnames';
import { isQueueScreenDarkTheme } from '../../../constants/API';

export const QueueHeader = () => {
  const [time, setTime] = useState<string>();
  const [date, setDate] = useState<string>();

  useEffect(() => {
    const intervalId = setInterval(() => {
      const now = new Date();
      setTime(format(now, 'HH:mm'));
      setDate(format(now, 'd MMMM', { locale: ru }));
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <header
      className={classNames(styles.headerContainer, {
        [styles.darkThemeHeaderContainer]: isQueueScreenDarkTheme
      })}>
      <HeaderLogoIcon />
      <div className={styles.dateTimeContainer}>
        <div className={styles.timeSectionComponent}>
          <HeaderCalendarIcon />
          <div
            className={classNames(styles.date, {
              [styles.darkThemeText]: isQueueScreenDarkTheme
            })}>
            <span>{date}</span>
          </div>
        </div>

        <div className={styles.timeSectionComponent}>
          <HeaderClockIcon />
          <div
            className={classNames(styles.time, {
              [styles.darkThemeText]: isQueueScreenDarkTheme
            })}>
            <span>{time}</span>
          </div>
        </div>
      </div>
    </header>
  );
};

export default QueueHeader;
