import React, { FC, useEffect, useState } from 'react';
import classNames from 'classnames';
import PoorVisionTerminalHeader from '../PoorVisionTerminalHeader/PoorVisionTerminalHeader';
import classes from './PoorVisionMode.module.scss';
import { TerminalMode } from '../../../../../pagesMain/TerminalPage/TerminalPage';
import { languageType } from '../../Normal/NormalMode/NormalMode';
import { ButtonsEditTheme } from '../ButtonsEditTheme/ButtonsEditTheme';
import { ThemeColors } from '../../../../../types/enums';
import {
  CategoryTopicsType,
  CategoryType,
  OrderUnitsType,
  TopicsType
} from '../../../../../types/Terminal/TerminalTypes';
import { useGetTicket } from '../../../../../hooks/TerminalServices/useGetTicket';
import { ReactComponent as ArrowBack } from '../../../../../assets/icons/arrowBack.svg';
import TerminalModal from '../../../Modals/TerminalModal/TerminalModal';

type PoorVisionModePropsType = {
  setMode: (mode: TerminalMode) => void;
  orderUnitsData: OrderUnitsType;
  tickerText?: string;
};

const PoorVisionMode: FC<PoorVisionModePropsType> = ({ setMode, orderUnitsData }) => {
  const [themeColor, setThemeColor] = useState<string>(ThemeColors.Blue);
  const [language, setLanguage] = useState<languageType>('ru');

  const [currentSectionsServices, setCurrentSectionsServices] = useState([]);
  const [history, setHistory] = useState<{ [key: string]: CategoryTopicsType[] }>({});
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [activeModal, setActiveModal] = useState(false);
  const [activeTicketId, setActiveTicketId] = useState<number>();

  const { getTicket, isLoadingGetTicket } = useGetTicket(() => {
    if (currentIndex > 0) {
      setCurrentIndex(0);
      setCurrentSectionsServices(history[0]);
    }
  });

  useEffect(() => {
    if (isLoadingGetTicket) {
      setActiveModal(true);
    } else {
      setActiveModal(false);
    }
  }, [isLoadingGetTicket]);

  const bgThemeColor = classNames({
    [classes.bgBlue]: themeColor === ThemeColors.Blue,
    [classes.bgWhite]: themeColor === ThemeColors.White,
    [classes.bgBlack]: themeColor === ThemeColors.Black
  });

  useEffect(() => {
    if (orderUnitsData) {
      setCurrentSectionsServices(transformOrderUnitsToArr(orderUnitsData));

      if (currentIndex > 0) {
        setCurrentIndex(0);
      }
    }
  }, [orderUnitsData]);

  const handleCategoryClick = (category: CategoryTopicsType) => {
    if (checkIsCategory(category)) {
      setHistory((prev) => {
        const newHistory = { ...prev };
        newHistory[currentIndex] = currentSectionsServices;
        return newHistory;
      });
      setCurrentSectionsServices([...category.topics, ...category.subCategories]);
      setCurrentIndex((prev) => prev + 1);
    } else {
      setActiveTicketId(category.id);
      getTicket({ topicId: category.id });
    }
  };

  const handleBack = () => {
    if (currentIndex > 0) {
      setCurrentIndex((prev) => prev - 1);
      setCurrentSectionsServices(history[currentIndex - 1]);
    }
  };

  function transformOrderUnitsToArr(data: OrderUnitsType) {
    const topics = data.categoryView.topics || [];
    const categories = data.categoryView.categories || [];
    const processCategory = (category: CategoryTopicsType, prefix = '') => {
      const categoryId = `${prefix}${category.id}`;
      let subCategories = [];
      if (category.subCategories && category.subCategories.length > 0) {
        subCategories = category.subCategories.map((subCategory: CategoryTopicsType) => {
          return processCategory(subCategory, `${categoryId}.`);
        });
      }
      return {
        ...category,
        id: categoryId,
        subCategories: subCategories
      };
    };

    const processedCategories = categories.map((category: CategoryTopicsType) =>
      processCategory(category)
    );

    return [...topics, ...processedCategories];
  }

  function checkIsCategory(categoryOrTopic: CategoryTopicsType) {
    return (
      (categoryOrTopic.subCategories && categoryOrTopic.subCategories.length > 0) ||
      (categoryOrTopic.topics && categoryOrTopic.topics.length > 0)
    );
  }
  const handleClickTheme = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    const color = e.currentTarget.dataset.color;
    setThemeColor(color);
  };
  const getTicketName = (ticket: TopicsType | CategoryType, language: languageType): string => {
    switch (language) {
      case 'ru':
        return ticket.nameRu;
      case 'en':
        return ticket.nameEn;
      case 'by':
        return ticket.nameBe;
      default:
        return ticket.nameRu;
    }
  };
  return (
    <div className={bgThemeColor}>
      <TerminalModal activeModal={activeModal} setActiveModal={setActiveModal} />
      <div className={classes.poorVisionContainer}>
        <div className={classes.headerContainer}>
          <PoorVisionTerminalHeader
            language={language}
            setLanguage={setLanguage}
            setMode={setMode}
            themeColor={themeColor}
          />
        </div>
        <main className={classes.ticketsContainer}>
          <div
            className={classNames(classes.choiceServiceContainer, {
              [classes.withoutArr]: currentIndex !== 0
            })}>
            {currentIndex !== 0 && (
              <button onClick={handleBack} className={classNames(classes.buttonBack)}>
                <ArrowBack
                  className={classNames(classes.backImg, {
                    [classes.blackArrow]: themeColor === ThemeColors.Black
                  })}
                />
              </button>
            )}
            <span
              className={classNames(classes.title, {
                [classes.whiteTitle]: themeColor === ThemeColors.Black
              })}>
              ВЫБЕРИТЕ УСЛУГУ
            </span>
            <ButtonsEditTheme handleClickTheme={handleClickTheme} theme={themeColor} />
          </div>
          <div className={classes.ticketList}>
            {currentSectionsServices.length > 0 &&
              currentSectionsServices.map((ticket: TopicsType) => (
                <button
                  className={classNames(classes.ticket, {
                    [classes.noBorders]: themeColor === ThemeColors.Black,
                    [classes.smallText]:
                      (language === 'by' && ticket.nameBe.length > 28) ||
                      (language === 'ru' && ticket.nameRu.length > 28) ||
                      (language === 'en' && ticket.nameEn.length > 28)
                  })}
                  key={ticket.id}
                  onClick={() => handleCategoryClick(ticket)}
                  disabled={
                    (!ticket.isActiveNow && !checkIsCategory(ticket)) ||
                    (isLoadingGetTicket && activeTicketId === ticket.id)
                  }>
                  <span>{getTicketName(ticket, language)}</span>
                </button>
              ))}
          </div>
        </main>
      </div>
    </div>
  );
};

export default PoorVisionMode;
